const toastification_component = () => import('@core/components/toastification/ToastificationContent.vue');

export const showToast = (vue, title, text, icon) => {
  toastification_component().then((component) => {
    vue.$toast({
      component: component.default,
      props: {
        title,
        icon,
        text,
        variant: 'danger'
      }
    }, {
      position: 'top-center',
      timeout: 5000
    });
  });
  return false;
};

export const getIconWhite = (network) => {
  if (network === 'facebook') return require("@/assets/images/icons/facebook_white.svg")
  if (network === 'instagram' || network === 'instagram_reels') return  require("@/assets/images/icons/insta_white.svg")
  if (network === 'linkedin') return require("@/assets/images/icons/linkedin_white.svg")
  if (network === 'twitch') return require("@/assets/images/icons/twitch_white.svg")
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
  if (network === 'youtube') return require("@/assets/images/icons/youtube_white.svg")
  if (network === 'youtube_shorts') return require("@/assets/images/icons/yt_shorts.svg")
  if (network === 'tiktok') return require("@/assets/images/icons/tiktok_white.svg")
  if (network === 'spotify' || network === 'podcast') return require("@/assets/images/icons/spotify_white.svg")
  if (network === 'pinterest') return require("@/assets/images/icons/pinterest_white.svg")
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon-white.svg")
  if (network === 'snapchat') return require("@/assets/images/icons/snapchat_white.svg")
  if (network === 'reddit') return require("@/assets/images/icons/reddit_icon_white.svg")
  if (network === 'threads') return require("@/assets/images/svg/threads.svg")
}
export const getColor = (network, black_tiktok = false) => {
  if (network === 'facebook') return '#3b5998'
  if (network === 'instagram') return '#d6249f'
  if (network === 'linkedin') return '#346297'
  if (network === 'twitch') return '#924DF7'
  if (network === 'twitter') return '#0791ce'
  if (network === 'youtube') return '#c4302b'
  if (network === 'tiktok') return black_tiktok ? '#000000' : '#ff0650'
  if (network === 'spotify') return '#64D862'
  if (network === 'pinterest') return '#E73630'
  if (network === 'blog') return '#00749a'
  if (network === 'snapchat') return '#F5D90D'
};
export const getColorV2 = (network, color_default = true) => {
  if (network === 'facebook') return '#3b5998'
  if (network === 'instagram') return '#d6249f'
  if (network === 'linkedin') return '#346297'
  if (network === 'twitch') return '#924DF7'
  if (network === 'twitter') return '#0791ce'
  if (network === 'youtube') return '#c4302b'
  if (network === 'tiktok') return '#000000'
  if (network === 'spotify') return '#64D862'
  if (network === 'pinterest') return '#E73630'
  if (network === 'blog') return color_default ? '#FFF' : '#00749a'
  if (network === 'snapchat') return '#F5D90D'
};
export const getClassNetworkColor = (network) => {
  
  if (network === 'instagram') return require("@/assets/images/icons/insta_white.svg")
  if (network === 'linkedin') return require("@/assets/images/icons/linkedin_white.svg")
  if (network === 'twitch') return require("@/assets/images/icons/twitch_white.svg")
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
  if (network === 'youtube') return require("@/assets/images/icons/youtube_white.svg")
  if (network === 'tiktok') return require("@/assets/images/icons/tiktok_white.svg")
  if (network === 'spotify') return require("@/assets/images/icons/spotify_white.svg")
  if (network === 'pinterest') return require("@/assets/images/icons/pinterest_white.svg")
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon-white.svg")
  if (network === 'snapchat') return require("@/assets/images/icons/facebook_white.svg")
  if (network === 'facebook') return require("@/assets/images/icons/facebook_white.svg")
};
export const getIconColor = (network, padding_default = true) => {
  if (network === 'facebook') return `${padding_default ? 'padding-07' : 'padding-03'} facebook-border`
  if (network === 'instagram') return `${padding_default ? 'padding-07' : 'padding-03'} instagram-border`
  if (network === 'linkedin') return `${padding_default ? 'padding-07' : 'padding-03'} linkedin-border`
  if (network === 'twitch') return `${padding_default ? 'padding-07' : 'padding-03'} twitch-border`
  if (network === 'twitter') return `${padding_default ? 'padding-07' : 'padding-03'} twitter-border`
  if (network === 'youtube') return `${padding_default ? 'padding-07' : 'padding-03'} youtube-border`
  if (network === 'tiktok') return `${padding_default ? 'padding-07' : 'padding-03'} tiktok-border`
  if (network === 'spotify') return 'spotify-border padding-03'
  if (network === 'pinterest') return `${padding_default ? 'padding-07' : 'padding-03'} pinterest-border`
  if (network === 'blog') return 'word-press-border'
  if (network === 'snapchat') return `${padding_default ? 'padding-07' : 'padding-03'} snapchat-border`
  if (network === 'threads') return `${padding_default ? 'padding-07' : 'padding-03'} tiktok-border`
};
export const getClassNetworkSpan = (network) => {
  if (network === 'facebook') return 'icomoon-Facebook-2 facebook'
  if (network === 'instagram') return 'icomoon-instagram1 instagram'
  if (network === 'linkedin') return 'icomoon-Linkedin linkedin'
  if (network === 'twitch') return 'iconNetwork-twitch twitch'
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x-black.svg")
  if (network === 'youtube') return 'icomoon-youtube youtube'
  if (network === 'tiktok') return 'icomoon-tiktok tiktok'
  if (network === 'spotify') return 'iconNetwork-spotify spotify'
  if (network === 'pinterest') return 'icomoon-pinterest2 pinterest'
  if (network === 'blog') return 'iconNetwork-blogger blog'
  if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat'
};

export const getLocalNetworkName = (network) => {
  if (network === 'facebook') return 'facebook'
  if (network === 'ig') return 'instagram'
  if (network === 'instagram') return 'instagram'
  if (network === 'linkedin') return 'linkedin'
  if (network === 'twitch') return 'twitch'
  if (network === 'twitter') return 'twitter'
  if (network === 'yt') return 'youtube'
  if (network === 'youtube') return 'youtube'
  if (network === 'tt') return 'tiktok'
  if (network === 'tiktok') return 'tiktok'
  if (network === 'spotify') return 'spotify'
  if (network === 'pinterest') return 'pinterest'
  if (network === 'blog') return 'blog'
  if (network === 'snapchat') return 'snapchat'
  if (network === 'tr') return 'twitter'
  if (network === 'tw') return 'twitch'
};

export const getContent = (type) => {
  if (type === 'photo') return 'camera'
  if (type === 'video') return 'camera-video'
  if (type === 'story') return 'clock-history'
  if (type === 'streaming') return 'display'
  if (type === 'instagram_tv') return 'instagram'
  if (type === 'reel') return 'camera-reels'
  if (type === 'post') return 'images'
  if (type === 'snap') return 'file-earmark-play' 
  if (type === 'podcast') return 'soundwave'
  if (type === 'clip') return 'joystick'
  return 'award'
};
export const getDropdownNetworks = () => {
  return  [
    {
      value: "tiktok",
      network: "Tiktok",
      icon: require("@/assets/images/icons/tiktok_white.svg"),
    },
    {
      value: "spotify",
      network: "Spotify",
      icon: require("@/assets/images/icons/spotify_white.svg"),
    },
    {
      value: "snapchat",
      network: "Snapchat",
      icon: require("@/assets/images/icons/snapchat_white.svg"),
    },
    {
      value: "blog",
      network: "Blog",
      icon: require("@/assets/images/icons/wordpress-icon-white.svg"),
    },
    {
      value: "pinterest",
      network: "Pinterest",
      icon: require("@/assets/images/icons/pinterest_white.svg"),
    },

    {
      value: "linkedin",
      network: "LinkedIn",
      icon: require("@/assets/images/icons/linkedin_white.svg"),
    },
    {
      value: "youtube",
      network: "Youtube",
      icon: require("@/assets/images/icons/youtube_white.svg"),
    },
    {
      value: "instagram",
      network: "Instagram",
      icon: require("@/assets/images/icons/insta_white.svg"),
    },
    {
      value: "twitter",
      network: "Twitter",
      icon: require("@/assets/images/icons/twitter-x.svg"),
    },
    {
      value: "twitch",
      network: "Twitch",
      icon: require("@/assets/images/icons/twitch_white.svg"),
    },
    {
      value: "facebook",
      network: "Facebook",
      icon: require("@/assets/images/icons/facebook_white.svg"),
    },
  ]
};
export const getImageNetwork = (network) => {
  if (network === 'facebook') return require('@/assets/images/anlytics/facebook.png')
  if (network === 'instagram') return require('@/assets/images/anlytics/instagram.png')
  if (network === 'linkedin') return require('@/assets/images/anlytics/linkedin.png')
  if (network === 'twitch') return require('@/assets/images/anlytics/twitch.png')
  if (network === 'twitter') return require('@/assets/images/icons/twitter-x-black.svg')
  if (network === 'youtube') return require('@/assets/images/anlytics/youtube.png')
  if (network === 'tiktok') return require('@/assets/images/anlytics/tiktok.png')
  if (network === 'spotify') return require('@/assets/images/anlytics/spotify.png')
  if (network === 'pinterest') return require('@/assets/images/anlytics/pinterest.png')
  if (network === 'blog') return require('@/assets/images/icons/wordpress-icon.svg')
  if (network === 'snapchat') return require('@/assets/images/anlytics/snapchat.png')
};
export const getImageNetworkColor = (network, x_twitter = true) => {
  if (network === 'facebook') return require("@/assets/images/icons/facebook2.svg")
  if (network === 'instagram') return require("@/assets/images/icons/instagram2.svg")
  if (network === 'linkedin') return require("@/assets/images/icons/linkedin2.svg")
  if (network === 'twitch') return require("@/assets/images/icons/twitch2.svg")
  if (network === 'twitter') return `${x_twitter ? require("@/assets/images/icons/twitter-x.svg") : require("@/assets/images/icons/twitter-x-circle.svg")}` 
  if (network === 'youtube') return require("@/assets/images/icons/youtube2.svg")
  if (network === 'tiktok') return require("@/assets/images/icons/tiktok2.svg")
  if (network === 'spotify') return require("@/assets/images/icons/spotify2.svg")
  if (network === 'pinterest') return require("@/assets/images/icons/pinterest2.svg")
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon.svg")
  if (network === 'snapchat') return require("@/assets/images/icons/snapchat2.svg")
};

export const getIconInterest = (item) => {
  if (item === "NFT") return require("@/assets/images/Audience-interests/nft.svg")
  if (item === "Gaming" || item === "Video Gaming") return require("@/assets/images/Audience-interests/gaming.svg")
  if (item === "Crypto") return require("@/assets/images/Audience-interests/crypto.svg")
  if (item === "Politics") return require("@/assets/images/Audience-interests/politics.svg")
  if (item === "Business & Careers") return require("@/assets/images/Audience-interests/business.svg")
  if (item === 'Modeling') return require("@/assets/images/Audience-interests/modeling.svg")
  if (item === 'Fashion') return require("@/assets/images/Audience-interests/fashion.svg")
  if (item === 'Nature & landscapes') return require("@/assets/images/Audience-interests/nature.svg")
  if (item === 'Travel') return require("@/assets/images/Audience-interests/travel.svg")
  if (item === 'Photography') return require("@/assets/images/Audience-interests/photography.svg")
  if (item === 'Fitness & Gym') return require("@/assets/images/Audience-interests/fitness.svg")
  if (item === 'Trainers & Coaches') return require("@/assets/images/Audience-interests/coach.svg")
  if (item === 'Cinema & Actors/actresses') return require("@/assets/images/Audience-interests/cinema.svg")
  if (item === 'Management & Marketing') return require("@/assets/images/Audience-interests/marketing.svg")
  if (item === 'Beauty') return require("@/assets/images/Audience-interests/beauty.svg")
  if (item === 'Lifestyle') return require("@/assets/images/Audience-interests/lifestyle.svg")
  if (item === 'Music') return require("@/assets/images/Audience-interests/music.svg")
  if (item === 'Family') return require("@/assets/images/Audience-interests/family.svg")
  if (item === 'Finance & Economics') return require("@/assets/images/Audience-interests/finance.svg") 
  if (item === 'Art/Artists') return require("@/assets/images/Audience-interests/art.svg")
  if (item === 'Literature & Journalism' || item ===  'Literature') return require("@/assets/images/Audience-interests/literature.svg")
  if (item === 'Sports with a ball') return require("@/assets/images/Audience-interests/sport_ball.svg")
  if (item === 'Kids & Toys') return require("@/assets/images/Audience-interests/kids.svg")    
  if (item === 'Shows') return require("@/assets/images/Audience-interests/shows.svg")
  if (item === 'Humor & Fun & Happiness') return require("@/assets/images/Audience-interests/humor.svg")
  if (item === 'Winter sports') return require("@/assets/images/Audience-interests/sport_winter.svg")
  if (item === 'Adult content') return require("@/assets/images/Audience-interests/adult_content.svg")
  if (item === 'Tobacco & Smoking') return require("@/assets/images/Audience-interests/tabaco.svg")
  if (item === 'Alcohol') return require("@/assets/images/Audience-interests/alcohol.svg")
  if (item === 'Extreme Sports & Outdoor activity') return require("@/assets/images/Audience-interests/sport_xtreme.svg")
  if (item === 'Machinery & Technologies') return require("@/assets/images/Audience-interests/machine.svg")
  if (item === 'Luxury') return require("@/assets/images/Audience-interests/luxury.svg")
  if (item === 'Computers & Gadgets') return require("@/assets/images/Audience-interests/computer.svg")
  if (item === 'Clothing & Outfits') return require("@/assets/images/Audience-interests/outfits.svg")
  if (item === 'Mobile related') return require("@/assets/images/Audience-interests/mobile.svg") 
  if (item === 'Science') return require("@/assets/images/Audience-interests/science.svg")
  if (item === 'Racing Sports') return require("@/assets/images/Audience-interests/racing.svg")
  if (item === 'Cars & Motorbikes') return require("@/assets/images/Audience-interests/bikers.svg")
  if (item === 'Food & Cooking') return require("@/assets/images/Audience-interests/food.svg")
  if (item === 'Architecture & Urban Design') return require("@/assets/images/Audience-interests/architecture.svg")
  if (item === 'Animals') return require("@/assets/images/Audience-interests/animals.svg")
  if (item === 'Comics & sketches') return require("@/assets/images/Audience-interests/comics.svg")
  if (item === 'Health & Medicine') return require("@/assets/images/Audience-interests/salud.svg")
  if (item === 'Shopping & Retail') return require("@/assets/images/Audience-interests/shopping.svg")
  if (item === 'Accessories & Jewellery') return require("@/assets/images/Audience-interests/jewellery.svg")
  if (item === 'Water sports') return require("@/assets/images/Audience-interests/sport_water.svg")
  if (item === 'DIY & Design') return require("@/assets/images/Audience-interests/diy.svg")
  if (item === 'Sweets & Bakery') return require("@/assets/images/Audience-interests/bakery.svg")
  if (item === 'Education' || item === 'Educational') return require("@/assets/images/Audience-interests/education.svg")
  if (item === 'Volleyball') return require("@/assets/images/Audience-interests/Volleyball.svg")
  if (item === 'Football & Soccer') return require("@/assets/images/Audience-interests/soccer.svg")
  if (item === 'Baseball') return require("@/assets/images/Audience-interests/baseball.svg")
  if (item === 'American Football') return require("@/assets/images/Audience-interests/american.svg")
  if (item === 'Basketball') return require("@/assets/images/Audience-interests/basketball.svg")
  if (item === 'Social Issues') return require("@/assets/images/Audience-interests/social.svg")
  if (item === 'Film & Television') return require("@/assets/images/Audience-interests/television.svg")
  if (item === 'Entertainment') return require("@/assets/images/Audience-interests/entertainment.svg")
  if (item === 'Business and Careers') return require("@/assets/images/Audience-interests/careers.svg")
  if (item === 'Ice Hockey') return require("@/assets/images/Audience-interests/hockey.svg")
  if (item === 'Theater') return require("@/assets/images/Audience-interests/theater.svg")
  if (item === 'Dance') return require("@/assets/images/Audience-interests/dance.svg")
  if (item === 'Traveling') return require("@/assets/images/Audience-interests/traveling.svg")
  if (item === 'Unboxings') return require("@/assets/images/Audience-interests/unboxing.svg")
  if (item === 'Parenting & Relationships') return require("@/assets/images/Audience-interests/parenting.svg")
  if (item === 'Stand-up Comedy') return require("@/assets/images/Audience-interests/comedy.svg")
  if (item === 'Finance & Accounting') return require("@/assets/images/Audience-interests/accounting.svg")
  if (item === 'Running & Athletics') return require("@/assets/images/Audience-interests/running.svg")
  if (item === 'Fitness & Bodybuilding') return require("@/assets/images/Audience-interests/fit.svg")
  if (item === 'Tennis') return require("@/assets/images/Audience-interests/tennis.svg")
  if (item === 'Skincare') return require("@/assets/images/Audience-interests/skincare.svg")
  if (item === 'Yoga') return require("@/assets/images/Audience-interests/yoga.svg")
  if (item === 'Technology & Gadgets') return require("@/assets/images/Audience-interests/gadgets.svg")
  if (item === 'Dining & Nightlife') return require("@/assets/images/Audience-interests/dining.svg")
  if (item === 'Makeup') return require("@/assets/images/Audience-interests/makeup.svg")
  if (item === 'Nail Care') return require("@/assets/images/Audience-interests/nailcare.svg")
  if (item === 'Haircare') return require("@/assets/images/Audience-interests/haircare.svg")
  if (item === 'Hiking & Trekking') return require("@/assets/images/Audience-interests/hiking.svg")
  if (item === "Children's Games & Toys") return require("@/assets/images/Audience-interests/toys.svg")
  if (item === 'Gastronomy') return require("@/assets/images/Audience-interests/gastronomy.svg")
  if (item === 'Coffee Culture') return require("@/assets/images/Audience-interests/coffe.svg")
  if (item === 'Nutritious Foods') return require("@/assets/images/Audience-interests/nutritious.svg")
  if (item === 'Cooking') return require("@/assets/images/Audience-interests/cooking.svg")
  if (item === 'Philanthropy & Charity') return require("@/assets/images/Audience-interests/philanthropy.svg")
  if (item === 'Wrestling') return require("@/assets/images/Audience-interests/wrestling.svg")
  if (item === 'Religion') return require("@/assets/images/Audience-interests/religion.svg")
  if (item === 'Snowboarding') return require("@/assets/images/Audience-interests/snowboarding.svg")
  if (item === 'Ecology') return require("@/assets/images/Audience-interests/ecology.svg")
  if (item === 'Surfing') return require("@/assets/images/Audience-interests/surfing.svg")
  if (item === 'Outdoor Camping') return require("@/assets/images/Audience-interests/camping.svg")
  if (item === 'Skateboarding') return require("@/assets/images/Audience-interests/skateboarding.svg")
  if (item === 'Motorsports') return require("@/assets/images/Audience-interests/motorsports.svg")
  if (item === 'Space Exploration') return require("@/assets/images/Audience-interests/exploration.svg")
  if (item === 'Motorcycles') return require("@/assets/images/Audience-interests/motorcycles.svg")
  if (item === 'Yachting & Sailing') return require("@/assets/images/Audience-interests/yachting.svg")
  if (item === 'Space & Astronomy') return require("@/assets/images/Audience-interests/astronomy.svg")
  if (item === 'Automobiles') return require("@/assets/images/Audience-interests/automobiles.svg")
  if (item === 'Cosplay') return require("@/assets/images/Audience-interests/Cosplay.svg")
  if (item === 'Physics') return require("@/assets/images/Audience-interests/physics.svg")
  if (item === 'Boxing') return require("@/assets/images/Audience-interests/boxing.svg")
  if (item === 'Handicrafts') return require("@/assets/images/Audience-interests/handicrafts.svg")
  if (item === 'Architecture & Design') return require("@/assets/images/Audience-interests/design.svg")
  if (item === 'DIY Projects') return require("@/assets/images/Audience-interests/projects.svg")
  if (item === 'Property & Real Estate') return require("@/assets/images/Audience-interests/property.svg")
  if (item === 'Interior Design') return require("@/assets/images/Audience-interests/interiorDesign.svg")
  if (item === 'Anime, Manga & Comics') return require("@/assets/images/Audience-interests/anime.svg")
  if (item === 'Sustainability') return require("@/assets/images/Audience-interests/sustainability.svg")
  if (item === 'Grilling & BBQ') return require("@/assets/images/Audience-interests/bbq.svg")
  if (item === 'AI & Machine Learning') return require("@/assets/images/Audience-interests/ia.svg")
  if (item === 'Meditative Practices') return require("@/assets/images/Audience-interests/meditative.svg")
  if (item === 'Tea') return require("@/assets/images/Audience-interests/tea.svg")
  if (item === 'History') return require("@/assets/images/Audience-interests/history.svg")
  if (item === 'Beers') return require("@/assets/images/Audience-interests/beers.svg")
  if (item === 'Biology') return require("@/assets/images/Audience-interests/biology.svg")
};
export const IconTypeCompetationGreen = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift_green.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service_green.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience_green.svg")
};
export const IconTypeCompetation = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience.svg")
};
export const IconTypeCompetationPrimary = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift_primary.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service_primary.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience_primary.svg")
};
export const getColorPlan = (display_name) => {
  const plan_min = display_name.toLowerCase();

  if (plan_min === 'pro') return 'badge-plan-pro';
  if (plan_min === 'ia') return 'badge-plan-ia';
  if (plan_min === 'ia +') return 'badge-plan-ia_plus';


  if (display_name === 'Creador Pro') return 'badge-plan-pro';
  if (display_name === 'Creador Pro IA') return 'badge-plan-ia';
  if (display_name === 'Creador Pro IA+') return 'badge-plan-ia_plus';
};
export const getBadgeNetwork = (network) => {
  if (network === 'facebook') return 'fb-badge'
  if (network === 'instagram' || network === 'instagram_reels') return 'insta-badge'
  if (network === 'linkedin') return 'lkn-badge'
  if (network === 'twitch') return 'twch-badge'
  if (network === 'twitter') return 'twr-badge'
  if (network === 'youtube' || network === 'youtube_shorts') return 'yt-badge'
  if (network === 'tiktok') return 'tk-badge'
  if (network === 'spotify' || network === 'podcast') return 'spoti-badge'
  if (network === 'pinterest') return 'pinterest-badge'
  if (network === 'blog') return 'blog-badge'
  if (network === 'snapchat') return 'snap-badge'
  if (network === 'reddit') return 'reddit-badge'
};
export const getIconInteractions = (name) => {
  if (name === 'number_of_likes') return 'heart-fill'
  if (name === 'number_of_comments') return 'chat'
  if (name === 'number_of_shares') return 'box-arrow-up-right'
  if (name === 'number_of_rts') return 'box-arrow-up'
  if (name === 'number_of_times_saved') return 'bookmark'
  if (name === 'number_of_times_favs') return 'bookmark'
  if (name === 'number_of_reproductions') return 'play-circle'
  if (name === 'number_of_views') return 'eye'
  if (name === 'number_of_clicks_to_stickers') return 'cursor'
  if (name === 'number_of_clicks_to_the_link') return 'link-45deg'
  if (name === 'number_of_dislikes') return 'hand-thumbs-down'
  if (name === 'number_of_impressions') return 'graph-up'
  if (name === 'number_of_prints') return 'printer'
  if (name === 'number_of_responses') return 'question-circle'
  if (name === 'scope_number') return 'people'
  if (name === 'number_of_interactions') return 'bar-chart-line'
};
export const getIconCategories = (category, network) => {
  if (network === 'instagram') {
    if (category.id  === 1007) return 'bag'
    if (category.id  === 1022) return 'plus-square'
    if (category.id  === 1047) return 'truck'
    if (category.id  === 1009) return 'geo-alt-fill'
    if (category.id  === 1039) return 'camera-reels-fill'
    if (category.id  === 1021) return 'star-fill'
    if (category.id  === 1025) return 'image-alt'
    if (category.id  === 1038) return 'camera-fill'
    if (category.id  === 1023) return 'bicycle'
    if (category.id  === 1031) return 'bicycle'
    if (category.id  === 1032) return 'film'
    if (category.id  === 1044) return 'globe'
    if (category.id  === 1020) return 'flower1'
    if (category.id  === 1041) return 'emoji-sunglasses'
    if (category.id  === 1027) return 'music-note-beamed'
    if (category.id  === 1042) return 'house-door'
    if (category.id  === 1006) return 'graph-up'
    if (category.id  === 1002) return 'brush'
    if (category.id  === 1004) return 'book-fill'
    if (category.id  === 1013) return 'circle'
    if (category.id  === 1046) return 'puzzle-fill'      
    if (category.id  === 1017) return 'calendar-day'
    if (category.id  === 1036) return 'emoji-laughing'
    if (category.id  === 1012) return 'asterisk'
    if (category.id  === 1048) return 'x'
    if (category.id  === 1001) return 'cup-straw'
    if (category.id  === 1014) return 'cloud'
    if (category.id  === 1030) return 'cpu-fill'
    if (category.id  === 1043) return 'gem'
    if (category.id  === 1034) return 'laptop'
    if (category.id  === 1010) return 'music-player'     
    if (category.id  === 1015) return 'trophy-fill'
    if (category.id  === 1018) return 'stoplights'
    if (category.id  === 1035) return 'egg-fried'
    if (category.id  === 1003) return 'bricks'
    if (category.id  === 1026) return 'bug-fill'
    if (category.id  === 1016) return 'book-half'
    if (category.id  === 1028) return 'shop'
    if (category.id  === 1000) return 'watch'
    if (category.id  === 1011) return 'droplet-fill'
    if (category.id  === 1037) return 'building'
    if (category.id  === 1019) return 'heart'  
    if (category.id  === 1033) return 'award'
    if (category.id  === 6) return 'brightness-alt-high'
    if (category.id  === 8) return 'dice5'  
    if (category.id  === 23) return 'display'
    if (category.id  === 33) return 'flower2'
    if (category.id  === 37) return 'hand-thumbs-up'  
    if (category.id  === 11) return 'tree'
    if (category.id  === 1029) return 'eyeglasses'
    if (category.id  === 14) return 'bug-fill'  
    if (category.id  === 28) return 'cup-straw' 
    if (category.id  === 17) return 'bicycle'  
    if (category.id  === 1005) return 'briefcase'
  } else if (network === 'youtube') {
    if (category.id  === 2) return 'pencil'
    if (category.id  === 1) return 'bug-fill'
    if (category.id  === 12) return 'bicycle'
    if (category.id  === 4) return 'stoplights'
    if (category.id  === 5) return 'flower1'
    if (category.id  === 6) return 'menu-button-wide-fill'
    if (category.id  === 21) return 'cpu-fill'
    if (category.id  === 13) return 'egg-fried'
    if (category.id  === 22) return 'trophy'
    if (category.id  === 7) return 'brush'
    if (category.id  === 9) return 'award'
    if (category.id  === 10) return 'house-door'
    if (category.id  === 15) return 'emoji-laughing'
    if (category.id  === 8) return 'search'
    if (category.id  === 23) return 'puzzle-fill'
    if (category.id  === 18) return 'patch-question'
    if (category.id  === 11) return 'camera-reels-fill'
    if (category.id  === 17) return 'music-note-beamed'
    if (category.id  === 19) return 'card-heading'
    if (category.id  === 16) return 'film'
    if (category.id  === 14) return 'plus-square'
    if (category.id  === 20) return 'calendar-day'
    if (category.id  === 3) return 'soundwave'
    if (category.id  === 24) return 'geo-alt-fill'
  } else if (network === 'tiktok') {
    if (category.id  === 19) return 'cloud'
    if (category.id  === 17) return 'heart'
    if (category.id  === 1) return 'bug-fill'
    if (category.id  === 12) return 'bicycle'
    if (category.id  === 3) return 'brush'
    if (category.id  === 4) return 'stoplights'
    if (category.id  === 9) return 'music-note-beamed'
    if (category.id  === 13) return 'cup-straw'
    if (category.id  === 5) return 'flower1'
    if (category.id  === 24) return 'award'
    if (category.id  === 6) return 'emoji-laughing'
    if (category.id  === 25) return 'trophy'
    if (category.id  === 10) return 'house-door'
    if (category.id  === 16) return 'tree'
    if (category.id  === 7) return 'search'
    if (category.id  === 14) return 'dice5'
    if (category.id  === 11) return 'camera-reels-fill'
    if (category.id  === 15) return 'plus-square'
    if (category.id  === 26) return 'geo-alt-fill'
    if (category.id  === 8) return 'stopwatch'
  }
};